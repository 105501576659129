<template>
  <div class="tutor">
    <div class="header">
      <div class="center">
        {{ title }}
      </div>
      <div class="right"></div>
    </div>
    <div class="main" v-if="onloading == false">
      <div class="info-1">{{ text }}</div>
      <div class="img">
        <img :src="wechatUrl" alt="" />
      </div>
      <!-- <div class="btn">
                <button class="copy">
                    截图到微信扫码
                </button>
            </div> -->
      <div class="btn">
        <button class="copy">
          截图，微信识别
        </button>
        <textarea v-model="wechatId" id="copy-textarea" />
      </div>
    </div>
    <div class="on-loading" v-if="onloading">
      <van-skeleton title :row="5" />
    </div>
    <!-- <div class="footer">
            <footerMenu></footerMenu>
        </div> -->
  </div>
</template>

<script>
import { Notify, Skeleton } from "vant";
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import { flexQrcodeApi } from "@/api/flexQrcodeApi.js";
import { isMobile, isWeiXin } from "@/util/browser.js";
export default {
  components: {
    // footerMenu,
  },
  data() {
    return {
      wechatId: "hepulaocai",
      //wechatUrl: require("@/assets/images/zhujiao0.png"),
      wechatUrl: "",
      title: "",
      text: "",
      onloading: true,
    };
  },
  methods: {
    onCopyPassword() {
      this.$nextTick(() => {
        const text = document.getElementById("copy-textarea");
        text.select();
        if (document.execCommand("copy")) {
          document.execCommand("copy");
          Notify({
            type: "success",
            message: "已复制微信号:" + this.wechatId,
          });
        }
      });
    },
    getQRCode(id) {
      if (!id) {
        return;
      }
      flexQrcodeApi.qrcode(id).then((ret) => {
        if (ret.code == 0 && ret.data) {
          console.log("#", ret);
          this.onloading = false;
          const item = ret.data;
          this.title = item.title;
          this.text = item.text;
          document.title = this.text;
          // 系统配置有默认托底的
          if (item.weChat) {
            this.wechatId = item.weChat.wechatId;
            //this.wechatUrl = item.weChat.url;
            this.wechatUrl = item.currentUrlQrCodeBase64;
            console.log("##", this.wechatUrl);
          }
        }
      });
    },
  },
  mounted() {
    const isWeiXinVal = isWeiXin();
    const isMobileVal = isMobile();

    if (this.$route.query && this.$route.query.id) {
      const id = this.$route.query.id;
      if (isWeiXinVal) {
        this.$router.push({
          path: "/mk/wx-qrcode",
          query: {
            id: id,
          },
        });
      }
      // 小程序被下架了，注释掉
      // else if (isMobileVal) {
      //   this.$router.push({
      //     path: "/mk/h5-qrcode",
      //     query: {
      //       id: id,
      //     },
      //   });
      // }
      this.getQRCode(id);
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tutor {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: auto;
    margin: 20px 18px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
    .info-1 {
      font-weight: 500;
      font-size: 20px;
      color: #363637;
      letter-spacing: 3.92px;
      text-align: center;
      margin: 20px;
    }
    img {
      width: 220px;
      height: auto;
      margin: 0 0 20px 0;
    }
    .btn {
      display: flex;
      flex-direction: column;
      align-content: center;
      .copy {
        background: #ff8423;
        border-radius: 12px;
        width: 280px;
        height: 46px;
        border: none;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0.44px;
        font-weight: 500;
      }
      #copy-textarea {
        position: absolute;
        left: -99999px;
      }
    }
  }
  .on-loading {
    height: 500px;
    margin: 20px 18px;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
